import React from "react"
import Navbar from "../layout/navbar"
import WorkProject from "../common/workProject"
import uuid from "uuid"

export default function Work() {
  const projects = [
    {
      link: "https://sproducts.angel2h.com",
      image: "https://angel2h.com/dist/img/items/sproducts.png",
      category: "WEB - FULL Stack",
      title: "Sistema de Inventarios",
    },
    {
      link: "https://angel2h.com/proyectos/headphones",
      image: "https://angel2h.com/dist/img/items/headphones.png",
      category: "WEB DESING",
      title: "HeadPhones Landing Page",
    },
    {
      link: "https://angel2h.com/web/movies",
      image: "https://angel2h.com/dist/img/items/themoviedb.jpg",
      category: "WEB",
      title: "TheMovieDB Page",
    },
    {
      link: "https://angel2h.com/proyectos/natours",
      image: "https://angel2h.com/dist/img/items/natours.jpg",
      category: "WEB DESING",
      title: "Natours",
    },
    {
      link: "https://angel2h.com/proyectos/nexter",
      image: "https://angel2h.com/dist/img/items/nexter.png",
      category: "WEB DESIGN",
      title: "Nexter",
    },
    {
      link: "https://angel2h.com/proyectos/trillio",
      image: "https://angel2h.com/dist/img/items/trillio.png",
      category: "WEB DESIGN",
      title: "Trillio",
    },
  ]

  return (
    <React.Fragment>
      <Navbar inner={true} />
      <section id="work-a" className="text-center py-3">
        <div className="container">
          <h2 className="section-title">Mi trabajo</h2>
          <div className="bottom-line" />
          <p className="lead">Observa algunos de mis proyectos</p>
          <div className="items">
            {projects.map((project) => (
              <WorkProject project={project} key={uuid()} />
            ))}
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}
