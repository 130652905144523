import React, { Component } from "react";
import Navbar from "../layout/navbar";

import { sendNewMessage } from "../../actions/messageActions";

export default class Contact extends Component {
  state = {
    nombre: "",
    asunto: "",
    correo: "",
    numero: "",
    mensaje: "",
    success: false,
    loading: false,
    errors: {},
  };

  onChangeTextInput = (e) => this.setState({ [e.target.name]: e.target.value });

  onSubmit = () => {
    if (this.state.loading) return;

    const { nombre, asunto, correo, numero, mensaje } = this.state;
    this.setState({ loading: true });
    sendNewMessage(
      { nombre, asunto, correo, numero, mensaje, mty_key: "jamlkp102" },
      (err) => {
        let errors = {};
        if (err) {
          errors = err;
        }
        this.setState({
          loading: false,
          errors,
          success: !Object.keys(errors).length > 0,
        });
      }
    );
  };

  onClickSuccess = () => {
    this.setState({ asunto: "", mensaje: "", success: false, errors: {} });
  };

  render() {
    const {
      nombre,
      asunto,
      correo,
      numero,
      mensaje,
      loading,
      success,
      errors: { nombre_error, asunto_error, correo_error, mensaje_error },
    } = this.state;
    return (
      <React.Fragment>
        <Navbar inner={true} />
        <section id="contact-a" className="text-center py-3">
          <div className="container">
            <h2 className="section-title">Contactame</h2>
            <div className="bottom-line mb-1" />
            <div>
              <div className="text-fields">
                <input
                  type="text"
                  className={`text-input name-input ${
                    nombre_error && "invalid-input"
                  }`}
                  placeholder="Nombre *"
                  name="nombre"
                  value={nombre}
                  onChange={this.onChangeTextInput}
                  disabled={loading}
                />
                <input
                  type="text"
                  className={`text-input subject-input ${
                    asunto_error && "invalid-input"
                  }`}
                  placeholder="Asunto *"
                  name="asunto"
                  disabled={loading}
                  value={asunto}
                  onChange={this.onChangeTextInput}
                />
                <input
                  type="email"
                  className={`text-input email-input ${
                    correo_error && "invalid-input"
                  }`}
                  placeholder="Correo electronico *"
                  name="correo"
                  disabled={loading}
                  value={correo}
                  onChange={this.onChangeTextInput}
                />
                <input
                  type="text"
                  className="text-input phone-input"
                  placeholder="Numero de telefono"
                  name="numero"
                  disabled={loading}
                  value={numero}
                  onChange={this.onChangeTextInput}
                />
                <textarea
                  className={`text-input message-input ${
                    mensaje_error && "invalid-input"
                  }`}
                  placeholder="Mensaje *"
                  name="mensaje"
                  disabled={loading}
                  value={mensaje}
                  onChange={this.onChangeTextInput}
                />
              </div>
              {loading && (
                <span
                  style={{
                    display: "block",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Enviando...
                </span>
              )}

              {success && (
                <span
                  style={{
                    display: "block",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  className="cursor-pointer"
                  onClick={this.onClickSuccess}
                >
                  Genial! He recibido tu mensaje y lo respondere lo mas pronto
                  posible
                </span>
              )}

              <button
                className="btn-dark"
                type="button"
                onClick={this.onSubmit}
              >
                Enviar
              </button>
            </div>
          </div>
        </section>

        <section id="contact-b" className="py-3 bg-dark text-white">
          <div className="container">
            <div className="contact-info">
              <div>
                <i className="fas fa-envelope fa-2x" />
                <h3>Correo</h3>
                <p>contacto@angel2h.com</p>
              </div>
              <div>
                <i className="fas fa-address-card fa-2x" />
                <h3>Direccion</h3>
                <p>Cortes, Honduras</p>
              </div>
            </div>
          </div>
        </section>

        <section id="contact-c" className="bg-main py-4">
          <div className="container">
            <p className="text-center text-white">
              ¡Construyamos tu proximo proyecto!
            </p>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
