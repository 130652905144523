import React from "react";
import PropTypes from "prop-types";

const progressBar = ({ title, progress }) => {
  return (
    <React.Fragment>
      <h4>{title}</h4>
      <div className="progress">
        <div style={{ width: `${progress}%` }} />
      </div>
    </React.Fragment>
  );
};

progressBar.propTypes = {
  title: PropTypes.string.isRequired,
  progress: PropTypes.string.isRequired
};

export default progressBar;
