import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const navbar = ({ inner }) => {
  let header_content;

  if (!inner) {
    header_content = (
      <div className="header-content">
        <h1>
          Yo soy Angel Hernandez el{" "}
          <span
            className="txt-type"
            data-wait="3000"
            data-words='["Desarrollador", "Autodidacta", "Analista"]'
          />
        </h1>
        <p className="lead">Me especializo en desarrollo web</p>
        <Link to="/trabajo" className="btn-light">
          Observa mis Proyectos
        </Link>
      </div>
    );
  }
  return (
    <header id={inner ? "header-inner" : "header-home"}>
      <div className="container">
        <nav id="main-nav">
          <img
            src="https://angel2h.com/dist/img/a2hlogo.png"
            alt="Mi Portafolio"
            id="logo"
          />
          <ul>
            <li>
              <Link to="/">Inicio</Link>
            </li>
            <li>
              <Link to="/acerca_de">Acerca de mi</Link>
            </li>
            <li>
              <Link to="/trabajo">Proyectos</Link>
            </li>
            <li>
              <Link to="/contacto">Contacto</Link>
            </li>
          </ul>
        </nav>
        {header_content}
      </div>
    </header>
  );
};

navbar.propTypes = {
  inner: PropTypes.bool.isRequired
};

navbar.defaultProps = {
  inner: false
};

export default navbar;
