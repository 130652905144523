import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./public/css/main.css";

import Footer from "./components/layout/footer";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Work from "./components/pages/Work";
import Contact from "./components/pages/Contact";
import NotFound from "./components/pages/NotFound";

const App = () => {
  return (
    <Router>
      <React.Fragment>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/acerca_de" component={About} />
          <Route exact path="/trabajo" component={Work} />
          <Route exact path="/contacto" component={Contact} />
          <Route component={NotFound} />
        </Switch>
        <Footer />
      </React.Fragment>
    </Router>
  );
};

export default App;
