import axios from "axios";
import { API_URL } from "../config/config";

export const sendNewMessage = (messageData, callback) => {
  axios
    .post(`${API_URL}/messages/send`, messageData)
    .then(res => {
      callback(null);
    })
    .catch(err => {
      let errors = {};
      if (err.response.data && err.response.data.data) {
        errors = err.response.data.data;
      }
      callback(errors);
    });
};
