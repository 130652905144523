import React, { Component } from "react";
import Navbar from "../layout/navbar";

import typeWriter from "../../utils/typeWriter";

class Home extends Component {
  componentDidMount() {
    typeWriter();
  }

  getHorasTrabajadas = () => {
    const current_date = new Date();
    const anios = current_date.getFullYear() - 2016 - 1;
    const dia_actual = current_date.getDay();
    const meses_transcurridos = current_date.getMonth() + 1;
    const horas_transcurridas = current_date.getHours();
    let dias_transcurridos =
      parseInt(`${current_date.getDate()}`.padStart(2, "0")) - 1;
    if (dias_transcurridos < 0) dias_transcurridos = 0;

    const horas_trabajo_diario = 9;

    let horas_trabajadas = anios * 200 * 8;
    horas_trabajadas += dias_transcurridos * horas_trabajo_diario;
    horas_trabajadas += meses_transcurridos * 24 * horas_trabajo_diario;

    if (dia_actual !== 0) {
      if (horas_transcurridas > 8) {
        if (horas_transcurridas > 17) {
          horas_trabajadas += horas_trabajo_diario;
        } else {
          if (dia_actual === 6) {
            if (horas_trabajadas < 12) {
              horas_trabajadas += horas_transcurridas - 8;
            } else {
              horas_trabajadas += 5;
            }
          } else {
            horas_trabajadas += horas_transcurridas - 8;
          }
        }
      }
    }
    return horas_trabajadas;
  };

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <section id="home-a" className="text-center py-2">
          <div className="container">
            <h2 className="section-title">Me especializo en</h2>
            <div className="bottom-line" />
            <p className="lead">
              Desarrollo de paginas y aplicaciones web, administracion de
              servidores, dise&ntilde;o y programacion de base de datos,
              dise&ntilde;o y prototipado de aplicaciones
            </p>
            <div className="specials">
              <div>
                <i className="fas fa-robot fa-3x"> </i>
                <h3>BACKEND</h3>
                <p>Desarrollo de BackEnd a medida para aplicaciones en linea</p>
              </div>
              <div>
                <i className="fas fa-paint-brush fa-3x"> </i>
                <h3>UI/UX</h3>
                <p>Dise&ntilde;o y prototipado de interfaces</p>
              </div>
              <div>
                <i className="fas fa-desktop fa-3x"> </i>
                <h3>Desarrollo Web</h3>
                <p>Creacion de aplicaciones web responsivas</p>
              </div>
              <div>
                <i className="fab fa-linux fa-3x"> </i>
                <h3>Servidores</h3>
                <p>Administracion de servidores Linux</p>
              </div>
            </div>
          </div>
        </section>

        <section id="home-b" className="text-center py-2">
          <div className="stats">
            <div>
              <ul>
                <li>
                  <i className="fas fa-users fa-3x" />
                </li>
                <li>
                  <i className="stats-title" />
                  Clientes
                </li>
                <li>
                  <i className="stats-number" />1
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  <i className="fas fa-award fa-3x" />
                </li>
                <li>
                  <i className="stats-title" />
                  Certificaciones
                </li>
                <li>
                  <i className="stats-number" />
                  14
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  <i className="fas fa-hourglass-start fa-3x" />
                </li>
                <li>
                  <i className="stats-title" />
                  Horas trabajadas
                </li>
                <li>
                  <i className="stats-number" />
                  {this.getHorasTrabajadas()}
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  <i className="fas fa-code-branch fa-3x" />
                </li>
                <li>
                  <i className="stats-title" />
                  Proyectos completados
                </li>
                <li>
                  <i className="stats-number" />6
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section id="home-c" className="text-center py-2">
          <div className="container">
            <h2 className="section-title">Mi proceso de desarrollo</h2>
            <div className="bottom-line" />
            <div className="process">
              <div>
                <i className="fas fa-lightbulb fa-4x process-icon my-2">
                  <div className="process-step">1</div>
                </i>
                <h3>Plenteamiento del problema</h3>
                <p>
                  Discutir el problema para seleccionar las tecnologias y
                  herramientas adecuadas para brindar una solucion viable
                </p>
              </div>

              <div>
                <i className="fas fa-object-ungroup fa-4x process-icon my-2">
                  <div className="process-step">2</div>
                </i>
                <h3>Dise&ntilde;ar el prototipo</h3>
                <p>
                  Crear un dise&ntilde;o de la aplicacion para plasmar
                  graficamente la solucion a construir
                </p>
              </div>

              <div>
                <i className="fas fa-code fa-4x process-icon my-2">
                  <div className="process-step">3</div>
                </i>
                <h3>Codificar</h3>
                <p>
                  Escribir el codigo usando los lenguajes de programacion
                  adecuados para el proyecto
                </p>
              </div>

              <div>
                <i className="fas fa-bug fa-4x process-icon my-2">
                  <div className="process-step">4</div>
                </i>
                <h3>Pruebas</h3>
                <p>
                  Reparar cualquier falla o agregar caracteristicas no
                  consideradas en el principio
                </p>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Home;
