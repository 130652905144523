import React from "react";
import PropTypes from "prop-types";

const workProject = ({ project: { link, title, image, category } }) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <div className="item">
        <div className="item-image">
          <img src={image} alt="" />
        </div>
        <div className="item-text">
          <div className="item-text-wrap">
            <p className="item-text-category">{category}</p>
            <h2 className="item-text-title">{title}</h2>
          </div>
        </div>
      </div>
    </a>
  );
};

workProject.propTypes = {
  project: PropTypes.object.isRequired
};

export default workProject;
