import React from "react";
import Navbar from "../layout/navbar";

export default function NotFound() {
  return (
    <React.Fragment>
      <Navbar inner={true} />
      <div className="container">
        <h1
          className="text-center"
          style={{
            paddingTop: "100px",
            paddingBottom: "100px",
            fontSize: "70px"
          }}
        >
          <i class="fas fa-exclamation-triangle"></i> 404
        </h1>
      </div>
    </React.Fragment>
  );
}
