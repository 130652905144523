import React from "react";
import Navbar from "../layout/navbar";

import ProgressBar from "../common/progressBar";

export default function About() {
  return (
    <React.Fragment>
      <Navbar inner={true} />
      <section id="about-a" className="text-center py-3">
        <div className="container">
          <h2 className="section-title">Acerca de Angel</h2>
          <div className="bottom-line" />
          <p className="lead">A continuacion te cuento un poco sobre mi</p>
          <div className="about-info">
            <div className="bio-image" />
            <div className="bio bg-light">
              <h4>La tecnologia nos cambia la vida</h4>
              <p>
                Comence en el mundo del desarrollo de software hace{" "}
                {new Date().getFullYear() - 2016} a&ntilde;os al iniciar una
                ingeniera en computacion, explore tecnologias tipicas como: C++,
                Java, HTML, Python y Objective-C. <br />
                Con el paso del tiempo me fui introduciendo mas y mas en el
                mundo del aprendizaje en linea mediante plataformas como Udemy y
                Platzi, siendo estas mismas las que me ayudaron a aprender a un
                ritmo acelerado todo lo que hoy en dia se.
              </p>
              <p>
                <br />
                <i>
                  El auto aprendizaje es el medio mas poderoso para el
                  desarrollo personal y profesional
                </i>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="about-b" className="bg-dark py-3">
        <div className="container">
          <h2 className="section-title">Habilidades tecnicas</h2>
          <div className="bottom-line" />

          <ProgressBar title="Javascript" progress="90" />
          <ProgressBar title="MySQL" progress="90" />
          <ProgressBar title="React" progress="90" />
          <ProgressBar title="Git" progress="90" />
          <ProgressBar title="NodeJS" progress="90" />
          <ProgressBar title="PHP" progress="80" />
          <ProgressBar title="WordPress" progress="80" />
          <ProgressBar title="Swift" progress="60" />
          <ProgressBar title="C#" progress="50" />
        </div>
      </section>

      <section id="about-c" className="py-4 bg-light">
        <div className="container">
          <div className="about-logos">
            <img src="dist/img/about-logos/nodejs.png" alt="" />
            <img src="dist/img/about-logos/react.png" alt="" />
            <img src="dist/img/about-logos/mysql.png" alt="" />
            <img src="dist/img/about-logos/php.png" alt="" />
            <img src="dist/img/about-logos/swift.png" alt="" />
          </div>
        </div>
      </section>

      <section id="about-d" className="py-4">
        <div className="container">
          <h2 className="section-title">Testimonios</h2>
          <div className="bottom-line" />
          <p className="lead text-center">Observa lo que mis clientes dicen</p>
          <div className="testimonials">
            <div>
              <p>
                Angel sin duda alguna nos salvo de continuar trabajando y
                almacenando toda nuestra informacion en archivos Excel, ahora
                nuestros procesos y toma de decisiones son mas eficientes.
              </p>
              <ul>
                <li>
                  <a
                    href="https://rimeim.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://rimeim.com/files/icons/icon_rimeim.png"
                      alt=""
                    />
                  </a>
                </li>
                <li>Rimeim</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
