import React from "react"

export default function footer() {
  return (
    <footer id="main-footer">
      <div className="footer-content container">
        <p>
          Angel2H &copy; {new Date().getFullYear()}. Todos los Derechos
          Reservados
        </p>
        <div className="social">
          <a
            href="https://www.linkedin.com/in/angel-hernandez-868917168/"
            style={{ color: "#fff" }}
            target="_blank"
          >
            <i className="fab fa-linkedin cursor-pointer" />
          </a>

          <a
            href="https://github.com/Angel998"
            style={{ color: "#fff" }}
            target="_blank"
          >
            <i className="fab fa-github cursor-pointer" />
          </a>
        </div>
      </div>
    </footer>
  )
}
